@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #505050;
}

* {
  box-sizing: border-box;
}

img {
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0;
  padding: 0;
}

.app {
  background-image: url("./assets/background-mobile.jpg");
  background-attachment: fixed;
  background-position: center top;
  background-size: 102%;
}

.header {
  background: linear-gradient(to right, #3a4d5f 0%, #61809e 100%);
  color: #EBF5EE;
  padding: 2.5rem 6%;
}
.header h1 {
  margin: 0;
  font-size: 8.5vw;
}
.header .title2 {
  font-size: 4.8vw;
  margin: 4vw 0 3.5vw 0;
}
.header .title3 {
  font-size: 3.8vw;
  max-width: 30ch;
}
.header h2 {
  opacity: 0.9;
  margin: 0;
}

.icon-bar {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 12vw;
}

.links {
  display: flex;
  align-content: center;
  position: relative;
  padding: 0.5vw 3vw;
}
.links::after {
  content: "";
  background: #EBF5EE;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  border-radius: 100px;
}
.links a {
  display: grid;
  place-items: center;
  padding: 0.5em;
  z-index: 2;
  transition: transform 0.2s ease;
}
.links a:hover {
  transform: scale(1.08);
}
.links .linkedin {
  width: 24vw;
  margin-right: 0.5em;
}
.links .linkedin img {
  width: 100%;
}
.links .github {
  width: 20vw;
}
.links .github img {
  width: 100%;
}

.icons {
  display: none;
}

.cards-section {
  display: grid;
  grid-template-columns: 88%;
  justify-content: center;
  gap: 2rem;
  padding: 2.5rem 0;
}

.card {
  font-size: 4vw;
  font-weight: 500;
  transition: transform 0.2s ease;
}
.card:hover {
  transform: scale(1.012);
}

.card--info-wrapper {
  border-radius: 1.2em 1.2em 0 0;
  width: 100%;
  background: linear-gradient(150deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
}

.card--info {
  padding: 1.3em 1.6em 1.8em 1.6em;
}

.card--title {
  font-size: 4.5vw;
  font-weight: bold;
  margin: 0 0 1em 0;
  opacity: 0.9;
}

.card--text {
  padding: 0.2em 0;
  opacity: 0.8;
  font-weight: 500;
}

.card--image {
  width: 100%;
}

footer {
  background: linear-gradient(to right, #3a4d5f 0%, #61809e 100%);
  color: #EBF5EE;
  font-size: 3.5vw;
  padding: 0.9em 0;
  padding-left: 6%;
}

@media (min-width: 800px) {
  .app {
    background-image: url("./assets/background-desktop.jpg");
  }
  .header {
    padding: 2.8rem 15%;
  }
  .header h1 {
    font-size: 4.5vw;
  }
  .header .title2 {
    font-size: 2vw;
    margin: 1.1vw 0 1vw 0;
  }
  .header .title3 {
    font-size: 1.2vw;
    max-width: none;
  }
  .icon-bar {
    margin-top: 4.8vw;
  }
  .links {
    padding: 0.1vw 1.4vw;
  }
  .links .linkedin {
    width: 8.5vw;
  }
  .links .github {
    width: 7.5vw;
  }
  .icons {
    display: flex;
    align-content: center;
    gap: 1.5rem;
    position: relative;
    padding: 0.1vw 1.4vw;
  }
  .icons::after {
    content: "";
    background: #EBF5EE;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    border-radius: 100px;
  }
  .icons img {
    z-index: 2;
  }
  .cards-section {
    grid-template-columns: 70%;
  }
  .card {
    display: flex;
    justify-content: space-between;
    font-size: 1.5vw;
  }
  .card--info-wrapper {
    border-radius: 0.8em 0 0 0.8em;
  }
  .card--image {
    width: 32vw;
  }
  .card--title {
    font-size: 2vw;
    margin-top: 0.3em;
    margin-bottom: 1.1em;
  }
  .card--info {
    padding-bottom: 0;
  }
  footer {
    padding-left: 15%;
    font-size: 1.25vw;
  }
}/*# sourceMappingURL=index.css.map */